.search-for-parts-section {
    margin: 0 auto;
}

.search-for-parts-main {
    background-color: #262626 !important;
    padding: var(--spacing-xxl);
}

.ui.selection.dropdown {
    background-color: #262626 !important;
}

.search-for-parts-heading {
    text-align: center;
    color: #ffffff;
}

.slick-slide {
    width: auto !important;
}

.slick-slide a {
    color: #000000 !important;
}

.slick-prev, .slick-next {
    background-color: #FFF !important;
}

.slick-prev:before, .slick-next:before {
    color: #000000 !important;
}


.MuiTab-textColorPrimary {
    color: #FFFFFF !important;

}

.MuiTabs-indicator {
    background-color: var(--secondary-color) !important;
}

.Mui-selected {
    color: var(--secondary-color) !important;
}


.search-for-parts-segment {
    border-color: #000000 !important;
}
