.section-dark-background {
    background: linear-gradient(180deg, #333333, #424242);
    color: #FFFFFF;


}

.ground_up_container {


}

.ground_up_img {

    margin-right: -700px;
}


.about_text_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about_heading {
    text-align: left;
    padding: 16px 16px 0 16px;
}

.about_text {
    text-align: left;
    font-size: 1em;


    padding: 16px;
}


@media (min-width: 200px) and (max-width: 599px) {
    .ground_up_img {
        padding-top: 8% !important;
    }
}