.argus-footer {
    background: rgb(203, 24, 24);
    background: -moz-linear-gradient(216deg, rgba(203, 24, 24, 1) 55%, rgba(255, 241, 0, 1) 55%);
    background: -webkit-linear-gradient(216deg, rgba(203, 24, 24, 1) 55%, rgba(255, 241, 0, 1) 55%);
    background: linear-gradient(216deg, rgba(203, 24, 24, 1) 55%, rgba(255, 241, 0, 1) 55%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cb1818", endColorstr="#fff100", GradientType=1);
}

.argus-footer-mobile {
    background: rgb(203, 24, 24);
    background: -moz-linear-gradient(180deg, rgba(255, 241, 0, 1) 50%, rgba(203, 24, 24, 1) 50%);
    background: -webkit-linear-gradient(180deg, rgba(255, 241, 0, 1) 50%, rgba(203, 24, 24, 1) 50%);
    background: linear-gradient(180deg, rgba(255, 241, 0, 1) 50%, rgba(203, 24, 24, 1) 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cb1818", endColorstr="#fff100", GradientType=1);
}