.header-segment {

}

.site-header-buttons-container {
    position: relative;
}

.site-header-alert-bubble {
    position: absolute;
    border-radius: 15px;
    top: -20px;
    left: 45px;
    width: 20px;
    height: 20px;
    font-size: var(--font-size-s);
    line-height: var(--line-height-s);
    text-align: center;
    color: white;
    background-color: var(--secondary-color);
}

.site-header-buttons {
    padding: var(--padding-m) !important;
    border: var(--border-radius-xs) solid var(--secondary-color) !important;
    background-color: var(--background-color) !important;
    border-radius: 20px !important;
}

/* Mobile Devices */
@media (max-width: 767px) {
    .site-header-alert-bubble {

    }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 1024px) {
    .site-header-alert-bubble {

    }
}

/* Desktop */
@media (min-width: 1025px) {
    .site-header-alert-bubble {

    }
}


@media (min-width: 600px) and (max-width: 999px) {

    .add_vehicle_container {

        left: 260px;


    }

    .link_text {
        margin-top: 0;
        display: none;
    }

    .warehouse_icon {
        width: 21px;
    }

}

@media(min-width: 100px) and (max-width: 499px) {


    .add_vehicle_text {
        color: #000000;
        font-size: 1.3em;
        margin-left: 2px !important;
    }

    .add_vehicle_container {
        top: 100px !important;
        left: -117px !important;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }


    .add_vehicle_container {
        top: 100px !important;
        left: -125px !important;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        margin-bottom: 20px !important;


    }

    .link_text {
        display: none !important;
        margin: 0 !important;
    }

    .account_buttons {
        display: none !important;

    }

    .search-bar {

        margin-left: 10px;


    }

    .warehouse_icon {
        width: 15px;
    }

}

@media (min-width: 500px) and (max-width: 599px) {


    .add_vehicle_link_container {
        margin-left: 0 !important;
    }

    .add_vehicle_text {
        color: #000000;
        font-size: 1.3em;
    }


    .add_vehicle_container {
        top: 100px !important;
        left: -87px;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        margin-bottom: 100px !important;


    }

    .link_text {
        display: none !important;
        margin: 0 !important;
    }

    .account_buttons {
        display: none !important;

    }

    .search-bar {

        margin-left: 10px;


    }

    .warehouse_icon {
        width: 15px;
    }
}

@media (min-width: 1450px) {
    .site-header-alert-bubble {

    }


    .add_vehicle_container {

        left: 610px;


    }

}

@media (min-width: 500px) and (max-width: 1200px) {
    .account_buttons {

        margin-right: 2%;
    }

    .link_text {
        margin-top: 2%;
        margin-right: 4%;

    }

}