button.button-bottom {
    margin-bottom: 18px !important;
}

.product_grid_main_container {
    padding-top: 20px !important;
}

.catergorySlider {
    margin-top: 45px !important;
    margin-bottom: 60px !important;
}

.category_name {
    text-align: center;
    color: #000000;

    font-size: 1.5em;
}

.active_product_name_container {
        background-color: lightgray !important;

        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
       

        padding-top: 2% !important;


    }

   
.filter_nav_sub_container,
    .filter_nav_sub_container{
        display: flex;
        align-items: center;
       text-align: center;
    }


 

@media (min-width: 500px) {
    .active_product_name {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

    }


    .active_product_name {
        padding: 3% !important;
    }

    .active_product_name_container {
        margin-bottom: 50px !important;
    }
}



@media(min-width: 500px) and (max-width: 1000px) {
    .product_grid_container {
        margin-left: 5px !important;
    }

    .product_grid_item {
        margin-left: 10px !important;
    }

    .catergorySlider {
        margin-top: 60px !important;
        margin-bottom: 20px !important;
        margin-left: 20px !important;

    }

    .active_product_name_container {
       
       

        padding-bottom: 6% !important;


    }
}


@media(min-width: 200px) and (max-width: 499px) {

    .argus-container {
        margin-bottom: 70px !important;
    }

    .category_name {

        font-size: 1.3em !important;

    }

    .product_grid_container {
        margin-left: 10px !important;
    }



    .active_product_name_container {
        margin-bottom: 20px !important;

    }

    .catergorySlider {



        margin: 30px 0 40px 20px !important;


    }

    
    .active_product_name_container {
       
       

        padding-bottom: 6% !important;


    }


}

.filter-table-link {
    padding: 1px;
    font-size: 1.1em !important;
}

.filter-table-link:hover {
    color: #0067b8;
}

.filter-table-link-active {
    color: #0067b8;
    font-size: 1.1em !important;
}

.wide-grid {
    max-width: 100% !important;
    margin: 0 0 0 3% !important;
}