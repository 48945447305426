.add-to-cart:hover {
    background-color: #E52727 !important;
    color: #FFFFFF !important;
}

.product-description-modal-container {
    overflow-y: scroll;
    max-height: 300px !important;
}

.product-description-modal {
    max-height: 300px !important;
}

.product-grid-item-add-to-cart {
    display: flex !important;
    align-content: center;

}

.product-grid-item-green-strip {
    min-height: 45px !important;
    max-height: 45px !important;
    text-align: center !important;
    line-height: 1em;
    color: white !important;
    background-color: #16ab39;
}

.product-grid-item-title {
    line-height: 18px !important;
    min-height: 35px !important;
    display: inline-block !important;
    white-space: nowrap;
    width: 100%; /* IE6 needs any width */
    overflow: hidden; /* "overflow" value must be different from  visible"*/
    -o-text-overflow: ellipsis; /* Opera < 11*/
    text-overflow: ellipsis;
}

.product-grid-item-money {
    color: #262626 !important;
    min-height: 10px !important;
    line-height: 16px !important;
}

.product-grid-item-image {
    min-height: 200px !important;
    max-height: 200px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background: #FFFFFF !important;
}

.product-grid-item-image img {
    height: 170px !important;
    width: auto !important;
    max-width: 130px !important;
}

.product-grid-item-image-container {
    min-height: 300px !important;
    max-height: 300px !important;
    display: flex !important;
    position: relative;
    text-align: center;
    color: white;
    background-color: #FFFFFF !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.green-strip {
    background-color: #2CB34C !important;
    color: white !important;
}

.yellow-strip {
    background-color: #EED912 !important;
    color: white !important;
}

.icon-margin {
    margin-left: 1em;
    padding: 0;
    margin-right: 0.2em;
}

.orange-strip {
    background-color: #F7941D !important;
    color: white !important;
}

.red-strip {
    background-color: #ED1C24 !important;
    color: white !important;
}

.segment-padding {
    padding: 0.2em 1em
}

.productQualityAPlus {
    color: #1F7FC4 !important;
}

.productQualityA {
    color: #15AAE2 !important;
}

.productQualityB {
    color: #4BC5D4 !important;
}

.productQualityOther {
    color: #3DBEA0 !important;
}

.top-left {
    position: absolute !important;
    top: 5px;
    left: 5px;
}

.top-left img {
    width: 45px !important;
    max-width: 45px !important;
}

.top-right {
    position: absolute !important;
    top: 5px;
    right: 5px;
}

.top-right img {
    width: 45px !important;
    max-width: 45px !important;
}

.bottom-right {
    position: absolute !important;
    bottom: 5px;
    right: 5px;
}

.top-right img {
    width: 45px !important;
    max-width: 45px !important;
}


@media (min-width: 200px) and (max-width: 599px) {
    .product_quality_popup {


        min-width: 260px !important;
    }

    .product_availability_popup_img {
        min-width: 266px !important;
    }


}