.top-left {
    position: absolute !important;
    top: 15px;
    left: 20px;
}

.top-left img {
    width: 45px !important;
    max-width: 45px !important;
}

.top-right {
    position: absolute !important;
    top: 5px;
    right: 5px;
}

.top-right img {
    width: 45px !important;
    max-width: 45px !important;
}

.bottom-right {
    position: absolute !important;
    bottom: 5px;
    right: 5px;
}

.top-right img {
    width: 45px !important;
    max-width: 45px !important;
}



.product_tab_headings {
  color: #000000 !important;
}

.product_heading {
  font-size: 1.3em !important;
  padding: 0 0 0 2% !important;



    margin: 0 !important;

  




}

.sku_product_heading {
  font-size: 0.8em !important;



}


.vendor_product_heading,
.sku_product_heading {

  color: red;
  background-color: rgba(211, 211, 211, 0.2);
  text-align: center;
  font-size: 0.8em;

  padding: 11%;



}

.sku_product_heading {
    margin-right: 10% !important;
}

.product_price_container {
  margin-left: 8% !important;
}

.quantity_input{
  max-width: 30% !important;
  text-align: center;
  margin-right: 20px !important;
   
}


@media (min-width: 100px) and (max-width: 499px) {


  .product_container {

    margin-left: 1px !important;
  }

  .argus-container {

    padding: 1rem 0.7rem !important;
  }



  .vendor_product_heading {
    font-size: .6em !important;
    padding: 14% 14% 14% 16% !important;
  }

  .sku_product_heading {

    font-size: .5em !important;
    margin-left: 25% !important;
    padding: 16% !important;

  }

  .product_heading {
    font-size: 1.4em !important;
    padding: 0 0 5% 13% !important;




    margin: 0 !important;



  }

  .product_price_heading {
    font-size: 1.4em !important;

    text-align: center;
  }

  .product_price_container {
    margin-left: 14% !important;

  }


}





@media(min-width: 500px) and (max-width: 1000px) {

  .argus-container {

    padding: 1rem 1rem !important;
  }

  .vendor_product_heading,
  .sku_product_heading {

    font-size: 0.8em !important;


  }

  .sku_product_heading {
    margin-left: 15% !important;
    padding: 10% !important;
  }

  .product_heading {
    font-size: 1.4em !important;
    padding: 0 0 5% 2% !important;
    text-align: center;


    margin: 0 !important;


  }




  .product_price_heading {
    font-size: 1.4em !important;

  }

  .product_price_container {
    margin-left: 17% !important;


  }

}