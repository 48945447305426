/* ===================== CART ======================*/
.text-align-center>input {
    text-align: center !important;
}

.cart-table-left thead {
    background: #f4f4f5 none repeat scroll 0 0;
    border: medium none;
}

.cart-table-left thead th {
    border: medium none;
    color: #001238;
}

.gauto-cart-preview {
    width: 115px;
    height: 100px;
    text-align: center;
}

.gauto-cart-preview img {
    width: 100%;
    height: 100%;
}

.cart-table-left table td p {
    color: #001238;

}

.gauto-cart-quantity>input {
    border: 1px solid #ddd;
    padding: 5px;
    width: 70px;
}

.gauto-cart-close {
    text-align: center;
}

.gauto-cart-total {
    font-weight: 500;
}

.gauto-cart-close a {
    color: #333;
    width: 35px;
    display: inline-block;
    height: 35px;
    line-height: 35px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.cart-clear a {
    display: inline-block;
    font-weight: 500;
    text-transform: uppercase;
    color: #001238;
    margin-right: 15px;
}

.cart-clear {
    margin-top: 20px;
}

.cart-clear a:last-child {
    margin-right: 1px;
}

.cart-clear a:hover {
    color: #ec3323;
}

.order-summary-box {
    padding: 30px;
    background: #f4f4f5 none repeat scroll 0 0;
}

.order-summary-box table {
    width: 100%;
}

.order-summary-box table tr {
    border-bottom: 1px solid #ddd;
}

.order-summury-box table tr:last-child {
    border-bottom: 0px solid #ddd;
}

.order-summary-box table td {
    padding: 10px 0;
    color: #001238;
}

.checkout-action {
    text-align: right;
    margin-top: 30px;
}

.checkout-action a.gauto-btn {
    color: #000000;
    margin: 0;
}

.checkout-action a.gauto-btn:hover {
    color: #FFFFFF;
}

.cart_items_headings {
    background-color: lightgray;

    padding-left: 11px !important;

}

.cart_items_container {
    padding-top: 0 !important;
}

.cart_product_heading {
    color: #000000;
}

.cart-item-name {
    color: #4d4d4f !important;
    font-size: 16px !important;
}

.cart-item-money {
    color: #0a0a0a !important;
    font-size: 18px !important;
    font-weight: bold;
    width: 100%;
    text-align: right;
}

.input-compact input {
    width: 15px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}


.checkout_button_container{
    margin-bottom: 20% !important;
}

.remove_item_button{
  cursor: pointer;
  color: lightgray !important;

}

.remove_item_button:hover{
    color: gray !important;
}


@media only screen and (min-width: 1200px) {
    .quantity_input_container{
        margin-left: 20px !important;
        margin-right: 50px !important;
    
    
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .order-summary-box {
        margin-top: 30px;
    }
}

@media (max-width: 767px) {
    .order-summary-box {
        margin-top: 30px;
    }

    .checkout-action {
        text-align: left;
    }
}

@media only screen and (min-width: 280px) and (max-width: 767px) {
    .order-summary-box {
        margin-top: 30px;
    }

    .checkout-action {
        text-align: left;
    }
    .quantity_input_container{
        margin-left: 8px !important;

    
    
    }


    .cart_product_price {
        font-size: 1em !important;
    }

    .cart_product_heading {
        font-size: 1em !important;
        margin-top: 10px !important;
    }

}

