:root {
    /* Spacing */
    --spacing-xxs: 0.25rem;
    --spacing-xs: 0.5rem;
    --spacing-s: 0.75rem;
    --spacing-m: 1rem;
    --spacing-l: 1.25rem;
    --spacing-xl: 1.75rem;
    --spacing-xxl: 2.5rem;

    /* Colors */
    --background-color: #f5f5f5;
    --text-color: #333333;
    --text-secondary-color: #666666;
    --primary-color: #f5f5f5;
    --secondary-color: rgb(203, 24, 24);
    --accent-color: rgba(255, 241, 0, 1);
    --surface-color: #ffffff;
    --blue-button-color: #0b79bf;

    /* Typography */
    --font-family: 'Roboto', sans-serif;
    --font-size-xxs: 0.75rem;
    --line-height-xxs: 1.125rem;
    --font-size-xs: 0.875rem;
    --line-height-xs: 1.3125rem;
    --font-size-s: 1rem;
    --line-height-s: 1.5rem;
    --font-size-m: 1.125rem;
    --line-height-m: 1.6875rem;
    --font-size-l: 1.25rem;
    --line-height-l: 1.875rem;
    --font-size-xl: 1.5rem;
    --line-height-xl: 2.25rem;
    --font-size-xxl: 2rem;
    --line-height-xxl: 3rem;

    /* Borders and Shadows */
    --border-radius-xs: 0.125rem;
    --border-radius-s: 0.25rem;
    --border-radius-m: 0.5rem;
    --border-radius-l: 0.75rem;
    --border-radius-xl: 1rem;
    --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    /* Layout */
    --max-width: 1200px;
    --header-height: 64px;
    --header-height-mobile: 56px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    width: 100%;
    overflow-x: hidden;
    margin: 0;
    height: 100%;
    padding: 0;
    background-color: var(--background-color);
    font-size: var(--font-size-m);
    line-height: var(--line-height-m);
}

.container {
    background-color: var(--background-color);
}

.MuiPaper-root {
    background-color: var(--surface-color) !important;
}