input#auto-complete {
    border: 0 !important;
}

.google-font {
    font-family: Roboto, serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    padding: 16px;
    letter-spacing: 0.0575em; /* 0.69px */
    color: #5F6368;
}